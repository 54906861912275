<template>
  <div>
    <XDataTable
        title="Received CDRs"
        :headers="headers"
        :items-request="subscriberMailbox.getReceivedCdrs"
        search>
      <template #[`item.cdr.filename`]="{value, row}">
        <XBtn icon="mdi-file" :tooltip="value" @click="openCdrDialog(row.cdr)"/>
      </template>
    </XDataTable>
    <XDialog v-if="selectedCdr" v-model="cdrDialog" :title="`Provided in ${selectedCdr.filename}`" width="1200">
      <template #dialog-content>
        {{ computedContent }}
      </template>
    </XDialog>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable.vue';
import XDialog from '@/components/basic/XDialog.vue';
import XBtn from '@/components/basic/XBtn.vue';
import subscriberMailbox from "@/js/services/SubscriberMailboxService";
import { isoDateTimeStringToDateTimeString } from "@/js/general";

export default {
  name: 'ReceivedCdrsView',
  components: {
    XBtn,
    XDialog,
    XDataTable,
  },
  data() {
    return {
      cdrDialog: false,
      selectedCdr: null,
      isoDateTimeStringToDateTimeString: isoDateTimeStringToDateTimeString,
      headers: [
        {
          text: 'Start Time',
          value: 'cdr.data.start_time',
          formatter: v => isoDateTimeStringToDateTimeString(v),
          width: 150,
        },
        {
          text: 'Subscriber',
          value: 'subscriber',
          sortable: true,
          width: 135,
        },
        {
          text: 'Received From',
          value: 'origin',
          sortable: true,
          width: 160,
        },
        {
          text: 'Network Session ID',
          value: 'cdr.data.network_session_id',
        },
        {
          text: 'Subscriber Session ID',
          value: 'cdr.data.subscriber_session_id',
        },
        {
          text: 'IMSI',
          value: 'cdr.data.imsi',
          width: 151,
        },
        {
          text: 'IMEI',
          value: 'cdr.data.imei',
          width: 151,
        },
        {
          text: 'Network Type',
          value: 'cdr.data.network_type',
          width: 135,
        },
        {
          text: 'Network Provider',
          value: 'cdr.data.network_provider',
          width: 160,
        },
        {
          text: 'Version',
          value: 'cdr.version',
          width: 160,
        },
        {
          text: 'Service',
          value: 'cdr.data.BasicService',
          width: 160,
          sortable: true,
        },
        {
          text: 'Raw',
          value: 'cdr.filename',
          width: 100,
        },
      ],
    };
  },
  computed: {
    subscriberMailbox() {
      return subscriberMailbox
    },
    computedContent() {
      let content = this.selectedCdr.content;
      if (content) content = content.replaceAll('\t', ' | ');
      return content;
    },
  },
  methods: {
    openCdrDialog(cdr) {
      this.selectedCdr = cdr;
      this.cdrDialog = true;
    },
  },
};
</script>

<style scoped>

</style>